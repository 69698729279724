import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setPrebindError,
  setTerminateError,
  setSelectorOpened,
} from "../Main/actions";
import {
  URL_STORE_HUAWEI,
  URL_STORE_ANDROID,
  URL_STORE_APPLE,
  URL_STORE_DEFAULT,
  WEB_FLOW,
  TOBI_OFF_MESSAGE,
  LATEST_VERSION,
  STANDALONE_FLOW,
  MVA_FLOW,
} from "../../constants";
import { IState } from "../Main/model";
import SsoSelector from "../../Components/SsoSelector/SsoSelector";
import Loading from "../../Components/Loading/Loading";
import Error from "../../Components/Error/Error";

const Home = ({
  getUserInfo,
  setLoggedIn,
  loggedIn,
  handleLogout,
}: {
  getUserInfo: any;
  setLoggedIn: any;
  loggedIn: boolean;
  handleLogout: any;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let {
    baseAccessToken,
    appFlow,
    baseRefreshToken,
    selectedAccount,
    isTobiOn,
    selectorOpened,
    isLoading,
    errorScreen,
  } = useSelector((state: IState) => state.app);
  const [showVersionWarning, setShowVersionWarning] = useState(false);
  const [appStoreLink, setAppStoreLink] = useState("");
  const ua = navigator.userAgent.toLowerCase();
  let dataLayer = window.dataLayer || [];

  useEffect(() => {
    onPageLoaded();
    dispatch(setPrebindError(false));
    dispatch(setTerminateError(false));

    if (appFlow === MVA_FLOW) {
      const currentVersion = localStorage.getItem("appVersion");
      if (
        (LATEST_VERSION &&
          currentVersion &&
          LATEST_VERSION.localeCompare(currentVersion, "kn") === 1) ||
        currentVersion === null
      ) {
        setShowVersionWarning(true);
      }
    }
  }, []);

  useEffect(() => {
    if (showVersionWarning) {
      //we check if the phone brand is Huawei because Huawei phones use App Gallery instead of Google Play;
      if (ua.indexOf("huawei") > -1) {
        setAppStoreLink(URL_STORE_HUAWEI);
      } else if (ua.indexOf("android") > -1 || ua.indexOf("nokia") > -1) {
        //if it is Android (except Huawei) or Nokia, we have the link for Google Play
        setAppStoreLink(URL_STORE_ANDROID);
      } else if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1) {
        //we check if the userAgent contains Iphone or Ipad; for ios devices we have the App Store
        setAppStoreLink(URL_STORE_APPLE);
      } else {
        //default
        setAppStoreLink(URL_STORE_DEFAULT);
      }
    }
  }, [showVersionWarning]);

  useEffect(() => {
    if (baseAccessToken && baseRefreshToken) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [baseAccessToken, baseRefreshToken]);

  const onPageLoaded = () => {
    let dataObject = {
      event: "microData", // required for every push
      event_name: "page_view", // required for every push on every change of the content page (ex. dropdown from listing ) or url change
      page_name: "Tobi", // required name of the page
      page_type: "homepage", //could be homepage, product page, listing page, search page, etc
      page_platform: appFlow === WEB_FLOW ? "website" : "mva",
      page_charset: "UTF-8",
      page_country: "RO",
      page_domain: "tobi.vodafone.ro",
      page_locale: "RO-ro",
      page_section: "Tobi",
      page_channel: "vodafone.ro",
      user_selected_journey: "NO_FLOW", //possible values: ''NO_FLOW' (default), 'RET', 'ACQ', 'MNP', 'MFP', 'Servicii fixe', 'cartela', 'dongle', 'SERVICE_ONLY'
      client_upc: "no", // possible values "yes" or "no", "no" default
    };
    dataLayer.push(dataObject);
  };

  const handleOpenChat = () => {
    openChatClicked();
    navigate("/chat");
  };

  const openChatClicked = () => {
    let clickDataObject = {
      event: "microData", // required
      event_name: "action_click", // required
      click_element_name: "Hai sa vorbim", // required name of the clicked element
      page_name: "Tobi", // required name of the page where click happened
    };
    dataLayer.push(clickDataObject);
  };

  const handleOpenSelector = () => {
    changeIdentityClicked();
    dispatch(setSelectorOpened(true));
  };

  const changeIdentityClicked = () => {
    let clickDataObject = {
      event: "microData", // required
      event_name: "action_click", // required
      click_element_name: "Schimba identitatea", // required name of the clicked element
      page_name: "Tobi", // required name of the page where click happened
    };
    dataLayer.push(clickDataObject);
  };

  const gdprLinkClicked = () => {
    let clickDataObject = {
      event: "microData", // required
      event_name: "action_click", // required
      click_element_name: "Nota de informare", // required name of the clicked element
      page_name: "Tobi", // required name of the page where click happened
    };
    dataLayer.push(clickDataObject);
  };

  const renderTobiOnOff = () => {
    if (appFlow === MVA_FLOW || isTobiOn) {
      return (
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleOpenChat()}
        >
          Hai să vorbim
        </Button>
      );
    } else {
      return <h5 className="mt-3">{TOBI_OFF_MESSAGE}</h5>;
    }
  };

  const renderWhenNoError = () => {
    if (isLoading || (isTobiOn === null && (appFlow === WEB_FLOW || appFlow === STANDALONE_FLOW))) {
      return <Loading page="Home" />;
    } else {
      return (
        <Container fluid="md" className="text-center mt-2">
          {(appFlow === WEB_FLOW || appFlow === STANDALONE_FLOW) && loggedIn && (
            <Fragment>
              <Row className="pe-1">
                <Col
                  className="text-start change-identity px-3"
                  onClick={() => handleOpenSelector()}
                >
                  <h5>
                    <strong>Schimbă identitatea</strong>
                  </h5>
                  {selectedAccount && (
                    <p>
                      <strong>{selectedAccount}</strong> (selectat)
                    </p>
                  )}
                </Col>
                <Col className="text-end px-3">
                  <Button
                    type="button"
                    variant="primary"
                    onClick={() => handleLogout("Tobi")}
                  >
                    Deconectare
                  </Button>
                </Col>
              </Row>
              <br />
            </Fragment>
          )}
          <Row className={`${(appFlow === WEB_FLOW || appFlow === STANDALONE_FLOW) && loggedIn ? "mt-5 pb-3" : "py-3"}`}>
            <Col>
              {(appFlow === WEB_FLOW || appFlow === STANDALONE_FLOW) && (
                <img
                  src="https://static.vodafone.ro/tobi/images/tobi-icon.webp"
                  className="tobi-header"
                  alt="Tobi"
                />
              )}
              <h2>Salut, sunt TOBi,</h2>
              <h2>asistentul tău virtual.</h2>
              <Fragment>{renderTobiOnOff()}</Fragment>
            </Col>
          </Row>
          <Row className="py-3 privacy-url-container">
            <Col>
              <a
                href="https://www.vodafone.ro/termeni-proceduri-legale/tobi-privacy"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => gdprLinkClicked()}
              >
                Notă de informare privind prelucrarea datelor cu caracter
                personal
              </a>
            </Col>
          </Row>
          {showVersionWarning && (
            <Row className="py-3">
              <Col className="warning-message-container">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="exclamation-circle"
                  className="svg-inline--fa fa-exclamation-circle fa-w-16 mb-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                  />
                </svg>
                <p>
                  Pentru cea mai bună experiență cu TOBi, îți recomandăm să
                  actualizezi aplicația{" "}
                  <a
                    href={appStoreLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    My Vodafone
                  </a>{" "}
                  la ultima versiune.
                </p>
              </Col>
            </Row>
          )}
        </Container>
      );
    }
  };

  const renderWhenIdentitySelected = () => {
    if (
      (isTobiOn === null || errorScreen.show) &&
      (appFlow === WEB_FLOW ||
        appFlow === STANDALONE_FLOW)
    ) {
      return (
        <Error
          title={errorScreen.title}
          content={errorScreen.content}
          buttonText={errorScreen.buttonText}
          backButtonShow={errorScreen.backButtonShow}
          onClick={errorScreen.onClick}
          handleLogout={handleLogout}
          pageName="Tobi"
          loggedIn={loggedIn}
        />
      );
    } else {
      return <Fragment>{renderWhenNoError()}</Fragment>;
    }
  };

  const renderAfterSelectorInitialized = () => {
    if (
      selectorOpened &&
      (appFlow === WEB_FLOW ||
        appFlow === STANDALONE_FLOW)
    ) {
      return <SsoSelector getUserInfo={getUserInfo} />;
    } else {
      return <Fragment>{renderWhenIdentitySelected()}</Fragment>;
    }
  };

  // @ts-ignore
  return (
    <Fragment>
      {selectorOpened === null ? (
        <Loading page="Home" />
      ) : (
        <Fragment>{renderAfterSelectorInitialized()}</Fragment>
      )}
    </Fragment>
  );
};

export default Home;
