import React, { Fragment, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {
  MAINTENANCE_MESSAGE_BODY,
  MAINTENANCE_MESSAGE_TITLE,
  MAINTENANCE_MESSAGE_LINK,
  MAINTENANCE_MESSAGE_BODY_WHATSAPP,
  STANDALONE_FLOW,
  WEB_FLOW,
} from "../../constants";
import { useSelector } from "react-redux";
import { IState } from "../../Containers/Main/model";

const MaintenanceBanner = ({
  setLoggedIn,
  loggedIn,
  handleLogout,
}: {
  setLoggedIn: any;
  loggedIn: boolean;
  handleLogout: any;
}) => {
  let { baseAccessToken, baseRefreshToken, appFlow } = useSelector((state: IState) => state.app);

  useEffect(() => {
    if (baseAccessToken && baseRefreshToken) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [baseAccessToken, baseRefreshToken]);

  return (
    <Container fluid="md" className="text-center mt-2">
      <Row>
        {(appFlow === WEB_FLOW || appFlow === STANDALONE_FLOW) && loggedIn && (
          <Fragment>
            <Row className="pe-1">
              <Col className="text-end px-3">
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => handleLogout("Tobi")}
                >
                  Deconectare
                </Button>
              </Col>
            </Row>
            <br />
          </Fragment>
        )}
        <Col className="d-flex flex-column align-items-center py-5 mt-3">
          <img
            className="tobi-maintenance-avatar mb-3"
            alt="TOBi Mentenanta"
            src="https://static.vodafone.ro/tobi/images/Tobi-1mai.png"
          />
          <h3 className="font-weight-bold tobi-maintenance-body">
            {MAINTENANCE_MESSAGE_TITLE}
          </h3>
          <h5 className="mt-4 mb-3 text-center tobi-maintenance-title">
            {MAINTENANCE_MESSAGE_BODY}
            <br />
            {MAINTENANCE_MESSAGE_BODY_WHATSAPP}
            <a href={MAINTENANCE_MESSAGE_LINK}>{MAINTENANCE_MESSAGE_LINK}</a>
          </h5>
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenanceBanner;
