import {
  SET_APP_FLOW,
  SET_NICKNAME,
  SET_USER_NAME,
  SET_APP_PROPS,
  SET_REFRESH_STARTED,
  SET_CONVERSE_INFO,
  SET_PREBIND_ERROR,
  SET_TERMINATE_ERROR,
  GET_ANONYMOUS_TOKEN_REQUEST,
  GET_ANONYMOUS_TOKEN_ERROR,
  SET_SELECTED_ACCOUNT,
  SET_SELECTOR_OPENED,
  SET_IS_TOBI_ON,
  SET_IS_LOADING,
  SET_IS_IDENTITY_SELECTED,
  SET_ERROR_SCREEN,
  SET_HAS_USER_INFO_FINISHED,
  SET_NOTIFICATION_BAN,
  SET_SHOW_GDPR_MODAL,
  SET_APP_CLIENT_NAME,
  SET_APP_BASE_TOKENS,
} from "./constants";

export const setAppFlowAction = (appFlow: string | null) => ({
  type: SET_APP_FLOW,
  appFlow,
});

export const setNicknameAction = (nickname: string | null) => ({
  type: SET_NICKNAME,
  nickname,
});

export const setUserNameAction = (userName: string | null) => ({
  type: SET_USER_NAME,
  userName,
});

export const setAppPropsAction = (accessToken: string | null, refreshToken: string | null) => ({
  type: SET_APP_PROPS,
  accessToken,
  refreshToken
});

export const setRefreshStartedAction = (isTokenRefreshStarted: boolean | null) => ({
  type: SET_REFRESH_STARTED,
  isTokenRefreshStarted
})

export const setConverseInfoAction = (jid: string | null, sid: string | null, rid: string | null) => ({
  type: SET_CONVERSE_INFO,
  jid,
  sid,
  rid
});

export const getAnonymousTokenRequestAction = () => ({
  type: GET_ANONYMOUS_TOKEN_REQUEST
});

export const getAnonymousTokenErrorAction = (error: object | string | null) => ({
  type: GET_ANONYMOUS_TOKEN_ERROR,
  error
});

export const setPrebindError = (prebindError: boolean | null) => ({
  type: SET_PREBIND_ERROR,
  prebindError
})

export const setTerminateError = (terminateError: boolean | null) => ({
  type: SET_TERMINATE_ERROR,
  terminateError
})

export const setSelectedAccount = (selectedAccount: string | null, entityId: string | null) => ({
  type: SET_SELECTED_ACCOUNT,
  selectedAccount,
  entityId
})

export const setSelectorOpened = (selectorOpened: boolean | null) => ({
  type: SET_SELECTOR_OPENED,
  selectorOpened
})

export const setIsTobiOn = (isTobiOn: boolean | null) => ({
  type: SET_IS_TOBI_ON,
  isTobiOn
})

export const setIsLoading = (isLoading: boolean | null) => ({
  type: SET_IS_LOADING,
  isLoading
})

export const setIsIdentitySelected = (isIdentitySelected: boolean | null) => ({
  type: SET_IS_IDENTITY_SELECTED,
  isIdentitySelected
})

export const setErrorScreen = (errorScreen: object) => ({
  type: SET_ERROR_SCREEN,
  errorScreen
})

export const setHasUserInfoFinished = (hasUserInfoFinished: boolean) => ({
  type: SET_HAS_USER_INFO_FINISHED,
  hasUserInfoFinished
})

export const setNotificationBan = (notificationBan: string | null) => ({
  type: SET_NOTIFICATION_BAN,
  notificationBan
})

export const setShowGdprModal = (showGdprModal: boolean) => ({
  type: SET_SHOW_GDPR_MODAL,
  showGdprModal
})

export const setAppClientName = (appClientName: string) => ({
  type: SET_APP_CLIENT_NAME,
  appClientName,
});

export const setAppBaseTokens = (baseAccessToken: string | null, baseRefreshToken: string | null) => ({
  type: SET_APP_BASE_TOKENS,
  baseAccessToken,
  baseRefreshToken
});