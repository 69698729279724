export const SET_APP_FLOW = "SET_APP_FLOW";
export const SET_NICKNAME = "SET_NICKNAME";
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_APP_PROPS = "SET_APP_PROPS";
export const SET_REFRESH_STARTED = "SET_REFRESH_STARTED";
export const SET_CONVERSE_INFO = "SET_CONVERSE_INFO";
export const SET_PREBIND_ERROR = "SET_PREBIND_ERROR";
export const SET_TERMINATE_ERROR = "SET_TERMINATE_ERROR";
export const GET_ANONYMOUS_TOKEN_REQUEST = "GET_ANONYMOUS_TOKEN_REQUEST";
export const GET_ANONYMOUS_TOKEN_ERROR = "GET_ANONYMOUS_TOKEN_ERROR";
export const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
export const SET_SELECTOR_OPENED = "SET_SELECTOR_OPENED";
export const SET_IS_TOBI_ON = "SET_IS_TOBI_ON";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_IS_IDENTITY_SELECTED = "SET_IS_IDENTITY_SELECTED";
export const SET_ERROR_SCREEN = "SET_ERROR_SCREEN";
export const SET_HAS_USER_INFO_FINISHED = "SET_HAS_USER_INFO_FINISHED";
export const SET_NOTIFICATION_BAN = "SET_NOTIFICATION_BAN";
export const SET_SHOW_GDPR_MODAL = "SET_SHOW_GDPR_MODAL";
export const SET_APP_CLIENT_NAME = "SET_APP_CLIENT_NAME";
export const SET_APP_BASE_TOKENS = "SET_APP_BASE_TOKENS";
